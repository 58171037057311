<template>
  <div class="user-manage-page">
    <x-table
      :no-data-text="CA('users_check') ? '暂无数据' : '暂无数据查询权限'"
      :columns="table.columns"
      :data="table.data"
      :loading="table.loading"
      :config="config"
      @search="search"
      @page-change="pageChange"
      @page-size-change="pageSizeChange"
    ></x-table>
  </div>
</template>

<script>
import { mapState } from "vuex";
import CU from "@/common/util";
export default {
  name: "",
  data() {
    return {
      table: {
        columns: [
          {
            title: "用户账号",
            minWidth: 100,
            key: "account",
          },
          {
            title: "用户名称",
            mingWidth: 100,
            key: "name",
          },
          {
            title: "用户角色",
            mingWidth: 100,
            key: "roleName",
          },
          {
            title: "联系方式",
            mingWidth: 130,
            key: "phone",
          },
          {
            title: "电子邮箱",
            mingWidth: 100,
            key: "email",
          },
        ],
        data: [],
        loading: false,
      },
      config: {
        filter: {
          width: 200,
          filterBox: [
            {
              conditionName: "用户名称",
              component: "input",
              field: "name",
              defaultValue: "",
            },
          ],
        },
        page: {
          pageNo: 1,
          pageSize: 10,
          total: 0,
        },
      },
      search_data: {},
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user,
    }),
  },
  methods: {
    pageChange(pageNo) {
      this.config.page.pageNo = pageNo;
      this.getList();
    },
    search(data) {
      this.search_data = data;
      this.config.page.pageNo = 1;
      this.getList();
    },
    pageSizeChange(pageSize) {
      this.config.page.pageSize = pageSize
      this.getList()
    },
    getList() {
      this.table.loading = true;
      this.$post(this.$api.USER_MANAGE.CLIST, {
        pageNo: this.config.page.pageNo,
        pageSize: this.config.page.pageSize,
        ...this.search_data,
        category: this.user.category + 1,
      })
        .then((res) => {
          this.table.data = res.list;
          this.config.page.total = +res.total;
        })
        .finally(() => {
          this.table.loading = false;
        });
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style lang="less" scoped>
.user-manage-page {
  width: 100%;
  height: 100%;
}
</style>